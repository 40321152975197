module.exports = {
  STABLE_COIN_TOKEN: {
    name: "BUSD Token",
    symbol: "BUSD",
    address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    decimals: 18,
    chainId: 56,
  },
  WARDEN_TOKEN: {
    name: "Warden Token",
    symbol: "WARDEN",
    address: "0x0fEAdcC3824E7F3c12f40E324a60c23cA51627fc",
    chainId: 56,
    decimals: 18,
  },
};
