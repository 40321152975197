/**
 * warden price in USD
 */
import { WardenBestRate } from "@wardenswap/bestrate-sdk";
import { ethers } from "ethers";
import { STABLE_COIN_TOKEN, WARDEN_TOKEN } from "@/constants";
import BigNumber from "bignumber.js";

export const fetchWardenPrice = async () => {
  try {
    const provider = new ethers.providers.JsonRpcProvider(
      "https://bsc-dataseed.binance.org"
    );
    const warden = new WardenBestRate(provider, "bsc");
    const stableCoinAmountInWei = ethers.utils
      .parseUnits("100", STABLE_COIN_TOKEN.decimals)
      .toString();
    let bestRateResult = await warden.getQuote(
      STABLE_COIN_TOKEN.address,
      WARDEN_TOKEN.address,
      stableCoinAmountInWei,
      ethers.utils.parseUnits("5", "gwei"), // 5 Gwai
      { enableSplit: false }
    );
    const amountOutInBase = ethers.utils
      .formatUnits(bestRateResult.amountOut.toString(), WARDEN_TOKEN.decimals)
      .toString();
    const price = new BigNumber(100).div(amountOutInBase).toFixed(3);

    return price;
  } catch (error) {
    console.error("error:==>", error);
  }
};
